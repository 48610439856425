// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_vm d_bz";
export var storyRowWrapper = "B_hv d_hv d_bG";
export var storyLeftWrapper = "B_vn d_bw d_bL";
export var storyWrapperFull = "B_vp d_cz";
export var storyWrapperFullLeft = "B_pt d_cz d_bw d_bL d_bz";
export var contentWrapper = "B_mY d_hw";
export var storyLeftWrapperCenter = "B_vq d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "B_vr d_hC";
export var storyHeader = "B_vs d_hB d_s d_cp";
export var storyHeaderCenter = "B_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "B_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "B_vt d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "B_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "B_p5 d_fc d_W";
export var imageWrapperFull = "B_rc d_s d_D d_bb d_W";
// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "r_qR d_bz";
export var datasheetRowWrapper = "r_lx d_lx d_bG";
export var datasheetLeftWrapper = "r_qS d_bw d_bL";
export var datasheetWrapperFull = "r_qT d_cz";
export var datasheetWrapperFullLeft = "r_pn d_cz d_bw d_bL d_bz d_bQ";
export var contentWrapper = "r_mY d_ly";
export var contentWrapperCenter = "r_qV d_lD";
export var datasheetLeftWrapperCenter = "r_qW d_lB d_bw d_bz d_bL d_bF";
export var datasheetImageCenterWrapper = "r_lv d_lv d_s d_cr";
export var datasheetRightWrapperCenter = "r_qX d_lz";
export var datasheetFooter = "r_lC d_lC";
export var alignLeft = "r_pZ d_dr";
export var alignCenter = "r_bL d_ds";
export var alignRight = "r_p0 d_dt";
export var datasheetInnerWrapperNewLeft = "r_qY d_fb d_L d_W d_bw d_bL d_df";
export var datasheetInnerWrapperLeft = "r_qZ d_d9 d_L d_W d_bw d_bL d_b2 d_df d_c6";
export var datasheetInnerWrapperRight = "r_q0 d_fb d_L d_W d_bw d_bL d_df";
export var datasheetRowWrapperReverse = "r_q1 d_bG";
export var datasheetSubtitle = "r_q2 d_ct";
export var tableRow = "r_q3";
export var cell = "r_q4";
export var tableRowWrapper = "r_q5 d_s d_dr";
export var tableHeadingCell = "r_q6";
export var tableHeading = "r_q7";
export var tableRowStriped = "r_q8";
export var tableRowHeading = "r_q9";
export var dataTable = "r_rb d_s";
export var imageWrapper = "r_p5 d_fc d_W";
export var imageWrapperFull = "r_rc d_D d_s d_bb d_W";
export var imageWrapperIcon = "r_rd";
export var titleMargin = "r_rf d_cp";
export var datasheetInnerInnerWrapper = "r_rg d_s";
export var hasLabels = "r_rh";
export var label = "r_rj";
export var SmallSmall = "r_rk z_rk z_tl z_tJ";
export var SmallNormal = "r_rl z_rl z_tl z_tK";
export var SmallLarge = "r_rm z_rm z_tl z_tG";
// extracted by mini-css-extract-plugin
export var dark = "y_sD";
export var darkcookie = "y_sF";
export var tintedcookie = "y_sG";
export var regularcookie = "y_sH";
export var darkbase = "y_sJ";
export var tintedbase = "y_sK";
export var regularbase = "y_sL";
export var darkraw = "y_sM";
export var tintedraw = "y_sN";
export var regularraw = "y_sP";
export var darkchick = "y_sQ";
export var tintedchick = "y_sR";
export var regularchick = "y_sS";
export var darkherbarium = "y_sT";
export var tintedherbarium = "y_sV";
export var regularherbarium = "y_sW";
export var darkholiday = "y_sX";
export var tintedholiday = "y_sY";
export var regularholiday = "y_sZ";
export var darkoffline = "y_s0";
export var tintedoffline = "y_s1";
export var regularoffline = "y_s2";
export var darkorchid = "y_s3";
export var tintedorchid = "y_s4";
export var regularorchid = "y_s5";
export var darkpro = "y_s6";
export var tintedpro = "y_s7";
export var regularpro = "y_s8";
export var darkrose = "y_s9";
export var tintedrose = "y_tb";
export var regularrose = "y_tc";
export var darktimes = "y_td";
export var tintedtimes = "y_tf";
export var regulartimes = "y_tg";
export var darkwagon = "y_th";
export var tintedwagon = "y_tj";
export var regularwagon = "y_tk";
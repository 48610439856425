// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "v_jN d_jN d_cr";
export var galleryMasonryImage = "v_jM d_jM d_s d_bN d_dx";
export var alignLeft = "v_pZ d_fl d_bC d_dr";
export var alignCenter = "v_bL d_fm d_bz d_ds";
export var alignRight = "v_p0 d_fn d_bD d_dt";
export var galleryContainer = "v_r4 d_dS";
export var galleryContainerFull = "v_r5 d_dQ";
export var galleryRowWrapper = "v_r6 d_b8";
export var galleryGuttersImage = "v_jQ d_jQ d_G d_cr";
export var galleryNoGuttersImage = "v_jP d_jP d_G d_cz";
export var galleryTextGutters = "v_jK d_jK d_cs";
export var galleryTextNoGutters = "v_jL d_jL d_cs";
export var galleryTextMasonry = "v_r7 d_jK d_cs";
export var galleryImageWrapper = "v_r8 d_fc d_W";
export var descText = "v_r9 d_jR d_X d_6 d_4 d_5 d_k";
export var guttersDesc = "v_sb";
// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "G_fM d_fM d_bw d_bD";
export var navbarDividedRight = "G_fN d_fN d_bw";
export var menuLeft = "G_vT d_fK d_bw d_ds d_bL";
export var menuRight = "G_vV d_fK d_bw d_ds d_bL";
export var menuCenter = "G_vW d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var menuDivided = "G_vB d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var navbarItem = "G_vC d_bt";
export var navbarLogoItemWrapper = "G_fV d_fV d_by d_bL";
export var burgerToggle = "G_vX d_f8 d_bv d_bp d_W";
export var burgerToggleOpen = "G_vY d_f8 d_bv d_bp d_0";
export var burgerInput = "G_vZ d_fZ d_t d_bv d_X d_b8 d_dh d_bq";
export var burgerLine = "G_v0 d_fY d_bv d_W d_t d_bj d_cl";
export var burgerMenuLeft = "G_v1 d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuRight = "G_v2 d_f4 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuCenter = "G_v3 d_f5 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuDivided = "G_v4 d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var btnWrapper = "G_dZ d_cT d_cM d_bw d_bD d_s";
export var cancelBtn = "G_v5 d_bY d_cz d_dx";
export var icon = "G_nD";
export var secondary = "G_v6 d_by d_bL";
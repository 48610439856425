// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sc d_gs d_cp";
export var heroHeaderCenter = "w_gt d_gt d_cp d_ds";
export var heroHeaderRight = "w_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "w_sd d_gp d_cs";
export var heroParagraphCenter = "w_gq d_gq d_cs d_ds";
export var heroParagraphRight = "w_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "w_sf d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "w_sg d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "w_sh d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "w_sj d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "w_sk d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "w_sl z_sl";
export var heroExceptionNormal = "w_sm z_sm";
export var heroExceptionLarge = "w_sn z_sn";
export var Title1Small = "w_sp z_sp z_tl z_tm";
export var Title1Normal = "w_sq z_sq z_tl z_tn";
export var Title1Large = "w_sr z_sr z_tl z_tp";
export var BodySmall = "w_ss z_ss z_tl z_tF";
export var BodyNormal = "w_st z_st z_tl z_tG";
export var BodyLarge = "w_sv z_sv z_tl z_tH";